import "../css/fonts.css"
import "../css/style.scss"
import "../css/responsive.scss"
import Banner from "../components/home/banner"
import Video from "../components/home/video"
import Youtube from "../components/home/youtube"
import React, { Component } from 'react'
import DefaultTemplate from "../templates/default"
import AOS from 'aos';
import 'aos/dist/aos.css';

class Index extends Component {

    componentDidMount(){
        AOS.init({
            once: true
        })
    }

    render(){
        return  <DefaultTemplate> 
                    <Banner />  
                    <Video />    
                    <Youtube />   
                </DefaultTemplate>
    }

}
   
export default Index
   