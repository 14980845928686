import React, { Component }  from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';

import introVideo from '../../images/intro-video.mp4';
import videoBtn from '../../images/play-video.png';

class Video extends Component {

    playVideo = (e) => {
        e.preventDefault();
        let video = document.getElementById("video");
        let playbtn = document.getElementById("play-btn");
        video.play(); 
        playbtn.style.display      = "none";
        video.addEventListener("ended", function() {video.currentTime = 0;});
    };

    pauseVideo = (e) => {
        e.preventDefault();
        let video       = document.getElementById("video");
        let playbtn     = document.getElementById("play-btn");
        video.pause(); 
        playbtn.style.display      = "block";
    };


    render() {
    return <StaticQuery query={graphql`
    {

        wordpressPage(slug: { eq: "home" }){
            acf {
                video_image {
                    source_url
                }
                youtube_video_code
                video_heading
                video_content
                feature_text
                feature_1_image {
                    source_url
                }
                feature_1_subheading
                feature_1_heading
                feature_2_image {
                    source_url
                }
                feature_2_subheading
                feature_2_heading
                feature_3_image {
                    source_url
                }
                feature_3_subheading
                feature_3_heading
                video_button
            }
        }

    }
    `} render={props => (
        <div>  
            <div className="features-section">
                <div className="frame missiontxt">
                    <div className="mission" data-aos="fade-up" dangerouslySetInnerHTML={{ __html: props.wordpressPage.acf.feature_text }}>
                    </div>
                </div>     
            </div>
            <div className="features-section">
                <div className="frame">
                    <div className="feature-section" data-aos="zoom-in-up" data-aos-duration="900" data-aos-easing="ease-in-out-back">
                        <img src={ props.wordpressPage.acf.feature_1_image.source_url } />
                        <div className="feature-bottom">
                            <div className="subheading">{props.wordpressPage.acf.feature_1_subheading}</div>
                            <div className="heading">{props.wordpressPage.acf.feature_1_heading}</div>
                        </div> 
                    </div>
                    <div className="feature-section" data-aos="zoom-in-up" data-aos-duration="900" data-aos-delay="500" data-aos-easing="ease-in-out-back">
                        <img src={ props.wordpressPage.acf.feature_2_image.source_url } />
                        <div className="feature-bottom">
                            <div className="subheading">{props.wordpressPage.acf.feature_2_subheading}</div>
                            <div className="heading">{props.wordpressPage.acf.feature_2_heading}</div>
                        </div>
                    </div>
                    <div className="feature-section" data-aos="zoom-in-up" data-aos-duration="900" data-aos-delay="1000" data-aos-easing="ease-in-out-back">
                        <img src={ props.wordpressPage.acf.feature_3_image.source_url } />
                        <div className="feature-bottom">
                            <div className="subheading">{props.wordpressPage.acf.feature_3_subheading}</div>
                            <div className="heading">{props.wordpressPage.acf.feature_3_heading}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="video-section">
                <div className="frame">
                   
                    {/* <div className="mobile-video-heading heading" data-aos="fade-up" data-aos-duration="900">{props.wordpressPage.acf.video_heading}</div> */}

                    <div className="video-left" data-aos="fade-in" data-aos-duration="1500">
                        <a className="play-btn" id="play-btn" onClick={this.playVideo}><img src={ videoBtn } /></a>
                        <div className="white-block"></div>
                        <div className="video-player pause" onClick={this.pauseVideo}></div>
                        <video className="video-player" id="video" playsInline autoPlay>
                            <source src={ introVideo } type="video/mp4" />
                            <source src={ introVideo } type="video/ogg" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="video-right">
                        <div className="heading" data-aos="fade-up" data-aos-duration="900">{props.wordpressPage.acf.video_heading}</div>
                        <div className="content" data-aos="fade-up" data-aos-duration="900" dangerouslySetInnerHTML={{ __html: props.wordpressPage.acf.video_content }}></div>
                    </div>
                </div>
            </div>
            <div className="video-button">
                <div className="frame">
                    <a href="https://app.yc360.com.au/" className="ctabtn">
                        { props.wordpressPage.acf.video_button }
                    </a>
                </div>
            </div>
        </div>
    ) } />

    }
};

export default Video;