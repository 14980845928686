import React, {Component} from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import '../../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';
import loader from "../../images/enquiry-loader.gif";
import Popup from "../cwu-popup"
const isSSR = typeof window === "undefined";
const OwlCarousel = React.lazy(() =>
    import('react-owl-carousel')
)

class Banner extends Component {

    state = {
        isPopUpOpen: false,
        isFixedHeader: false
    };

    closePopup() {
        this.setState({
            isPopUpOpen: false
        }); 
    }

    openPopup = (e) => {
        e.preventDefault();
        this.setState({
            isPopUpOpen: true
        }); 
    };


    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const target = document.getElementById('home-menu').offsetTop;

        if(window.pageYOffset >  target ) {
          this.setState({
            isFixedHeader: true
          });
        } else {
          this.setState({
            isFixedHeader: false
          });
        }
    }

    render() {
    return <StaticQuery query={graphql`
    {

        wordpressPage(slug: { eq: "home" }){
            acf {
                banner_image {
                    source_url
                }
                banner_subheading
                banner_heading
                banner_content
                banner_button
                banner_bottom_content
                cwup_heading
                cwup_subheading
                cwup_first_name
                cwup_last_name
                cwup_email
                cwup_phone
                cwup_submit_button
                cwup_image {
                    source_url
                }
                cwup_success_1
                cwup_success_2
                cwup_error_1
                cwup_error_2
            }
        }

        allWordpressWpApiMenusMenusItems(filter: {
            slug:{
              eq:"main-menu"
            }
          }){
            edges{
              node{
                name
                items{
                  title
                  object_slug
                  url
                }
              }
            }
        }

        allWordpressWpLogos {
            edges {
              node {
                id
                featured_media {
                    source_url
                }
              }
            }
        }

    }
    `} render={props => (
        <div>
        {!isSSR && (
            <div>
                <div className="home-banner"> 
                    <div className="banner-left" >
                        <img src={props.wordpressPage.acf.banner_image.source_url} alt="banner image" /> 
                    </div>
                    <div className="banner-mobile" >
                        <img src={props.wordpressPage.acf.banner_image.source_url} alt="banner image" /> 
                    </div>
                    <div className="banner-right">
                        <div className="sub-heading" data-aos="fade-left" data-aos-duration="1200" >{props.wordpressPage.acf.banner_subheading}</div>
                        <div className="heading" data-aos="fade-left" data-aos-duration="1200" data-aos-delay="100" ><span>{props.wordpressPage.acf.banner_heading}</span></div>
                        <div className="content desktop-version" data-aos="fade-left" data-aos-duration="1200" data-aos-delay="200" dangerouslySetInnerHTML={{ __html: props.wordpressPage.acf.banner_content }}></div>
                        <div className="content mobile-version" data-aos="fade-left" data-aos-duration="1200" data-aos-delay="200">
                            <p>Take my financial independence<br/>
                            quiz to get a custom report based<br/>
                            on your unique financial situation,<br/>
                            and discover how long<br/>
                            it will take you to<br/>
                            achieve financial<br/>
                            independence.</p>
                        </div>
                        <div className="button main-button" data-aos="fade-left" data-aos-duration="1200" data-aos-delay="300">
                            <a href="https://app.yc360.com.au">{props.wordpressPage.acf.banner_button}</a>
                        </div>
                    </div>
                </div>
                <div className="button small-screen-button" data-aos="fade-left" data-aos-duration="1200" data-aos-delay="300">
                    <a href="https://app.yc360.com.au">{props.wordpressPage.acf.banner_button}</a>
                </div>
                <div id="home-menu" className="home-menu" >
                    <div className="frame">
                        {props.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(item => (
                            <div key={item.title} className="menu-item ">
                                { item.url === '#connectwithus' ? (
                                    <a onClick = {(event) => this.openPopup(event)} dangerouslySetInnerHTML={{ __html: item.title }}></a>
                                ) : (
                                    <Link className={( item.object_slug === 'home' ? window.location.pathname === '/' ? 'active' : '' : window.location.pathname === '/' + item.object_slug ? 'active' : '')} to={ item.object_slug === 'home' ? "/" : `/${item.object_slug}`} key={item.title} dangerouslySetInnerHTML={{ __html: item.title }}></Link>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={ "home-menu headermenu-f " + (this.state.isFixedHeader ? "headermenu-v" : "")  } >
                    <div className="frame">
                        {props.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(item => (
                            <div key={item.title} className="menu-item ">
                                { item.url === '#connectwithus' ? (
                                    <a onClick = {(event) => this.openPopup(event)} dangerouslySetInnerHTML={{ __html: item.title }}></a>
                                ) : (
                                    <Link className={( item.object_slug === 'home' ? window.location.pathname === '/' ? 'active' : '' : window.location.pathname === '/' + item.object_slug ? 'active' : '')} to={ item.object_slug === 'home' ? "/" : `/${item.object_slug}`} key={item.title} dangerouslySetInnerHTML={{ __html: item.title }}></Link>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="banner-bottom">
                    <div className="frame">
                        {props.wordpressPage.acf.banner_bottom_content }
                    </div>
                </div>
                <div className="logo-images">
                
                    <React.Suspense fallback={<div></div>}>
                        <OwlCarousel
                            className="owl-theme"
                            loop = { true }
                            dots = { false }
                            autoplay 
                            autoplayTimeout = { 6000 }
                            items = {10}
                            animateOut = { 'fadeOut' }
                            animateIn = { 'fadeIn' }
                            data-aos="fade-up"
                            autoWidth = { true }
                        >
                            {
                                props.allWordpressWpLogos.edges.map(item => (
                                    <div className="item" key={item.node.id} >
                                        <img src={item.node.featured_media.source_url} />
                                    </div> 
                                ))
                            }
    
                        </OwlCarousel>
                    </React.Suspense>
               
                </div>


                <Popup isPopUpOpen = { this.state.isPopUpOpen } closePopup={() => this.closePopup()}  />

            </div> 
        )}

        </div>
    ) } />
    }
};

export default Banner;