import React, { Component } from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';

class Youtube extends Component {

    //  constructor(props){
    //     super(props);
    //     this.setWrapperRef = this.setWrapperRef.bind(this);
    //     this.handleClickOutside = this.handleClickOutside.bind(this);
    // }

    state = {
       data : [],
    //    isPopUpOpen : false,
    //    currentIndex : null
    };

    // openPopup = (e, index) => {
    //     e.preventDefault();
    //     this.setState({
    //         isPopUpOpen: true,
    //         currentIndex : index
    //     }); 
    // };

    componentDidMount() {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'json';
        xhr.onreadystatechange = function() {
            if ( xhr.readyState == 4) {
                this.setState({
                    data: xhr.response.items
                }); 
                console.log(this.state.data);
                console.log(this.state.data[0].snippet.thumbnails.standard.url);
            }
        }.bind(this);
        xhr.open('GET', 'https://www.googleapis.com/youtube/v3/playlistItems?playlistId=PLmi6umLecXry8O_eh_BzsLKlGBnzynJdN&key=AIzaSyBzzK1ajgNRDi_YT7xbbPXSiuDVfI3HSUk&part=snippet&maxResults=3');
        xhr.send();
        // document.addEventListener('mousedown', this.handleClickOutside);
    }

    // componentWillUnmount() {
    //     document.removeEventListener('mousedown', this.handleClickOutside);
    // }

    // setWrapperRef(node) {
    //     this.wrapperRef = node;
    // }

    // handleClickOutside(event) {
    //     if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
    //         if(this.state.isPopUpOpen) {
    //             this.setState({
    //                 isPopUpOpen: false,
    //                 currentIndex : null
    //             }); 
    //         }
    //     }
    // }

    render() {
    return <StaticQuery query={graphql`
    {

        wordpressPage(slug: { eq: "home" }){
            acf {
                instagram_heading
                instagram_mobile_heading
            }
        }

    }
    `} render={props => (
        <div>
            <div className="youtube-section">
                <div className="frame">
                    <div className="heading" data-aos="fade-up">
                        <span>Checkout our youtube channel</span>
                    </div>
                    <div className="youtube-videos-wrapper">
                        { this.state.data.map((item, index) => (
                            item ? (
                                <div className="youtube-block" key={item.id} >
                                    <a href={ "https://www.youtube.com/watch?v=" + item.snippet.resourceId.videoId } target="_blank">
                                        <img src={item.snippet.thumbnails.maxres.url} />
                                        <div className="yt-title">{item.snippet.title}</div>
                                    </a>
                                </div>  
                            ) : null
                        ))}
                    </div>
                </div> 
            </div>

            {/* <div className="yt-popup fade-in" style={{display: this.state.isPopUpOpen ? 'flex' : 'none' }}>
                <div className="yt-popup-inner" ref={this.setWrapperRef}>
                    { this.state.data[this.state.currentIndex] ? (
                        <iframe src={ "https://www.youtube.com/embed/" + this.state.data[this.state.currentIndex].snippet.resourceId.videoId + '?autoplay=1&rel=0' } frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    ) : null
                    }
                </div>
            </div> */}
        </div>
    ) } />
    }
};

export default Youtube;